@media only screen and (min-width: 840px) and (max-width: 1200px) {
  .searchItem {
    width: calc(100% - 3%);
  }
  body,
  html {
    font-size: 14px;
  }
  .action-row {
    padding-right: 3%;
  }
  .table-50 > .table-flex > *:not(:last-child) {
    margin-right: 3%;
  }
  .user-table {
    margin-right: 3%;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1366px) {
  .user-table {
    margin-right: 4%;
  }
  .searchItem {
    width: calc(100% - 4%);
  }
  .inner-card--details {
    margin-right: calc(4% - 20px);
  }
  .action-row {
    padding-right: 4%;
  }
  body,
  html {
    font-size: 14.5px;
  }
  .table-50 > .table-flex > *:not(:last-child) {
    margin-right: 4%;
  }
}

/* @media only screen and (max-width: 839px) {
 
} */

@media only screen and (max-width: 767px) {
  body,
  html {
    font-size: 13px;
  }
}

.justify-center.inactivity-buttons > div {
  max-width: 200px !important;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 768px) {
  .dashboard-header {
    position: fixed;
    display: grid;
    width: 100% !important;
    height: 70px;
  }
  .HfSzy {
    display: none;
  }
}
