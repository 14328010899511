body,
html {
  font: 16px !important;
}

/* Anti Alias */

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: none !important;
}

p {
  line-height: 1.25;
}

.user-table {
  width: 100%;
}

.user-table.center-flex {
  margin-top: 10px;
}

.center-flex {
  justify-content: center;
  text-align: center;
  margin-top: 40px;
}

.w-50 {
  width: 50%;
}

.table-50>.table-flex>* {
  width: 50%;
}

.table-50>.table-flex {
  flex-direction: row !important;
  display: flex;
}

.data-table {
  overflow-x: auto;
}

.data-table td {
  word-wrap: break-word;
}

/* INPUT FIELDS */

.input-field {
  padding: 8px 14px;
  background-color: #ffffff;
  border: 1px solid #dfe3e9;
  box-sizing: border-box;
  border-radius: 5px;
  min-height: 46px;
  width: 100%;
  outline: none;
  font-size: 14px;
  font-weight: 600;
}

select.input-field {
  padding-right: 35px !important;
  appearance: none;
  background: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='5' viewBox='0 0 8 5' fill='none'%3e%3crect y='0.707031' width='1' height='5' rx='0.5' transform='rotate(-45 0 0.707031)' fill='%23BEBEBE'/%3e%3crect x='6.36328' width='1' height='5' rx='0.5' transform='rotate(45 6.36328 0)' fill='%23BEBEBE'/%3e%3c/svg%3e") no-repeat;
}

.input-field::placeholder {
  font-weight: 500;
}

.input-field:hover {
  border-color: #ced0da;
}

.input-field:focus {
  border-color: #40196d;
}

/* INPUT FIELDS END */

/* TABLES */

.table {
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px !important;
}

.table td,
.table th {
  padding: 18px 30px !important;
  vertical-align: top;
  font-size: 13.2px !important;
  color: #121212;
  text-align: left;
  white-space: nowrap;
}

.table td {
  vertical-align: text-top;
  text-transform: capitalize;
}

.table td.no-capitalize {
  text-transform: unset;
}

.table th {
  font-size: 12px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  background-color: #eff1ff;
}

.table .text-right {
  padding-right: 30px !important;
  text-align: right;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

table {
  width: 100% !important;
}

.table th,
.text-wrap table th,
.table td,
.text-wrap table td,
.table thead th,
.text-wrap table thead th {
  border-top: 1px solid #dee2e6;
}

.data-table {
  border: 1px solid #dfe2e5;
  border-radius: 4px;
}

.data-table thead tr td,
.data-table thead tr th {
  border: 0 !important;
}

.table {
  border: 0 !Important
}

.pagination-total {
  font-size: 13px;
  font-weight: 600;
  margin-right: 10px;
  white-space: nowrap;
}

/* TABLES END */

/* AESTHETICS*/

.text-xlbold {
  font-weight: 900 !important;
}

.text-xbold {
  font-weight: 800 !important;
}

.text-bold {
  font-weight: 700 !important;
}

.text-semi--bold {
  font-weight: 600;
}

.text-medium {
  font-weight: 500;
}

.text-normal {
  font-weight: 400;
}

.text-light {
  font-weight: 300;
}

.color-primary {
  color: #40196d;
}

.color-black {
  color: #000000;
}

.color-alt--black {
  color: rgba(0, 0, 0, 0.6);
}

.border-bottom {
  border-bottom: 1px solid rgba(64, 25, 109, 0.1);
}

.panel-padding--bg {
  padding: 25px;
}

.panel-padding--sm {
  padding: 10px 25px;
}

.icon-has-left--padding {
  padding-left: 14px;
}

.icon {
  display: flex;
  align-items: center;
}

.content-top--spacing---md {
  margin-top: 22px;
  width: 100%;
  max-width: 1300px;
  margin-left: 0;
  margin-right: auto;
}

.content-top--spacing---sm {
  margin-top: 15px;
}

.text-uppercase {
  text-transform: uppercase;
}

.padding-bottom--none {
  padding-bottom: 0 !important;
}

/* AESTHETICS ENDS */

.searchItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
  padding-bottom: 50px;
  width: calc(100% - 50px);
  text-align: center;
}

.search-heading {
  max-width: 300px;
  font-weight: 900;
  font-size: 1.15rem;
  margin-top: 30px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.paginate {
  display: flex;
}

.pagination h5 {
  font-weight: 700;
  font-size: 0.9rem;
}

.paginate {
  border: 1px solid #e6eaee;
  border-radius: 4px;
}

.paginate>div {
  font-weight: 600;
  padding: 10px;
  font-size: 0.9rem;
  cursor: pointer;
}

.paginate>div:not(:last-child) {
  border-right: 1px solid #e6eaee;
}

.paginate>div:not(.active) {
  color: rgba(0, 0, 0, 0.2);
}

.paginate div.page {
  color: #40196d;
}

.paginate div.page-active {
  border: 0.5px solid #40196d;
}

.paginate div.active {
  color: #40196d;
  font-weight: 700 !important;
}

.form-loading {
  position: relative;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  opacity: 0.8;
  pointer-events: none;
}

.form-loading span {
  opacity: 0;
  visibility: hidden;
}

.cursor {
  cursor: pointer;
}

.action-btn {
  text-transform: capitalize;
  font-weight: bold;
  color: #40196d;
  text-decoration: none;
  border: none;
  outline: none;
  background: none;
}

.action-btn.greyed-out-text {
  color: #979797;
}

.review-mode {
  color: #1dcbef;
}

.mr-10 {
  margin-right: 10px;
}

.w-full {
  width: 100%;
}

.card-container .card {
  max-width: 609px;
}

:root {
  --blue: #40196d;
  --white: #ffffff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#modalbg>div {
  border-radius: 6px;
  margin: auto 0;
}

#modalbg .card-content .header p {
  font-size: 16px;
}

#modalbg .cancel svg {
  width: 14px;
  height: 14px;
}

.label {
  display: flex;
  font-weight: normal;
  font-size: 13.5px;
  line-height: 18px;
  color: #000;
  text-transform: capitalize;
  margin-bottom: 10px;
}